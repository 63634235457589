import React, { useState, useEffect } from 'react'
import useShopifyProduct from '../../hooks/useShopifyProduct'
import styled from 'styled-components'

const StrikeTrough = styled.span`
  text-decoration: line-through;
`

const Price = ({ shopifyProduct }) => {
  const staticPrice = shopifyProduct.variants.edges[0].node.price
  const { product } = useShopifyProduct(shopifyProduct.handle)
  const [discountPrice, setDisountPrice] = useState()
  const [price, setPrice] = useState(staticPrice)

  useEffect(() => {
    if (product) {
      const dynamicPrice = product.variants.edges[0].node.price
      if (dynamicPrice.amount !== staticPrice) {
        setPrice(dynamicPrice.amount)
      }

      if (
        product &&
        product.variants &&
        product.variants.edges &&
        product.variants.edges[0] &&
        product.variants.edges[0].node &&
        product.variants.edges[0].node.compareAtPrice
      ) {
        const comparePrice =
          product.variants.edges[0].node.compareAtPrice.amount
        if (comparePrice) {
          setDisountPrice(comparePrice)
        }
      }
    }
  }, [product])

  return (
    <>
      {discountPrice && (
        <StrikeTrough>€{Number(discountPrice).toFixed(2)} </StrikeTrough>
      )}
      <span>€{Number(price).toFixed(2)}</span>
    </>
  )
}

export default Price
