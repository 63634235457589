import React from 'react'
import styled, { css } from 'styled-components'
import { mobileVW, colors, desktopBreakpoint, font } from '../../../styles'

// Utils
import { getPathname } from '../../../utils'

// Hooks
import useLayoutCtx from '../../../hooks/context/useLayoutCtx'

// Components
import { Link } from 'gatsby'

const ButtonWrapper = styled.div`
  padding: ${props =>
    props.smallerFont
      ? `10px 14px`
      : props.ctaType === 'link'
      ? `0`
      : `13px 24px`};
  font-size: ${props =>
    props.smallerFont || props.ctaType === 'link' ? `14px` : `18px`};
  font-family: ${props =>
    props.ctaType === 'link' ? `${font.larsseit}` : `${font.ben}`};
  color: ${props =>
    props.ctaType === 'primary'
      ? `${colors.brownLight}`
      : `${colors.brownDarkest}`};
  background-color: ${props =>
    props.ctaType === 'primary'
      ? `${colors.brownDark}`
      : props.ctaType === 'secondary'
      ? `white`
      : `none`};
  border: ${props =>
    props.ctaType === 'terciary'
      ? `solid 2px ${colors.beigeUltraLight}`
      : `none`};
  border-radius: ${props =>
    props.ctaType === 'primary' ||
    props.ctaType === 'secondary' ||
    props.ctaType === 'terciary'
      ? '50px'
      : '0'};
  text-align: center;
  width: max-content;
  text-transform: lowercase;
  margin: ${props => (props.ctaType === 'link' ? `0` : `auto`)};
  position: relative;

  button:first-child,
  a:first-child {
    opacity: 0;
    visibility: hidden;
  }

  button:last-child,
  a:last-child {
    &::after {
      content: '';
      display: ${props => (props.ctaType === 'link' ? `block` : `none`)};
      width: 100%;
      position: absolute;
      bottom: -2px;
      border-bottom: solid 1px ${colors.brownDarkest};
    }
    @media (min-width: ${desktopBreakpoint}) {
      &::after {
        bottom: -3px;
        border-bottom: solid 1px ${colors.brownDarkest};
      }

      &:hover {
        &::after {
          bottom: -1px;
          border-bottom: solid 2px ${colors.brownDarkest};
        }
      }
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    cursor: pointer;
    padding: ${props =>
      props.smallerFont
        ? `14px 24px`
        : props.ctaType === 'link'
        ? `0`
        : `14px 24px`};
    border: ${props =>
      props.ctaType === 'terciary'
        ? `solid 2px ${colors.beigeUltraLight}`
        : `none`};
    font-size: ${props =>
      props.smallerFont || props.ctaType === 'link' ? `14px` : `18px`};
    border-radius: ${props =>
      props.ctaType === 'primary' ||
      props.ctaType === 'secondary' ||
      props.ctaType === 'terciary'
        ? `50px`
        : '0'};
    width: ${props => (props.smallerFont ? '100%' : 'max-content')};
    &:hover {
      background-color: ${props =>
        props.ctaType === 'primary'
          ? `${colors.orangeButton}`
          : props.ctaType === 'terciary'
          ? `${colors.beigeUltraLight}`
          : `none`};
      border-color: none;
      color: ${props =>
        props.ctaType === 'primary' || props.ctaType === 'terciary'
          ? `${colors.brownDark}`
          : `${colors.brownDarkest}`};
    }
  }
`

const buttonStyles = css`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  padding: inherit;
`

const StyledButton = styled.button`
  ${buttonStyles}
`
const StyledLink = styled(Link)`
  ${buttonStyles}
`

const StyledExternalLink = styled.a`
  ${buttonStyles}
`

const Button = ({
  ctaType,
  type,
  value,
  text,
  slug,
  cta,
  external,
  className,
}) => {
  const { node_locale } = useLayoutCtx()

  /**
   * Wanted to change this component because only the text is clickable. But there are too many references to the a tag or button tag from outside this component.
   */

  return (
    <ButtonWrapper
      ctaType={ctaType}
      smallerFont={external}
      className={`${className} button_wrapper`}
    >
      {slug && !external ? (
        <>
          <a tabIndex='-1' aria-hidden='true'>
            {text}
          </a>
          <StyledLink key={text} to={getPathname(node_locale, slug)}>
            {text}
          </StyledLink>
        </>
      ) : slug && external ? (
        <>
          <a tabIndex='-1' aria-hidden='true'>
            {text}
          </a>
          <StyledExternalLink href={slug}>{text}</StyledExternalLink>
        </>
      ) : (
        <>
          <button tabIndex='-1' aria-hidden='true'>
            {text}
          </button>
          <StyledButton type={type} value={value} onClick={cta}>
            {text}
          </StyledButton>
        </>
      )}
    </ButtonWrapper>
  )
}

export default Button
