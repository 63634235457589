import React from 'react'

// Hooks
import { useLayoutCtx } from './context'

const useContentfulId = (type = 'product', id) => {
  const {
    node_locale,
    default_locale,
    contentfulProductIds,
    contentfulArticleIds,
  } = useLayoutCtx()

  const table = {
    product: contentfulProductIds,
    article: contentfulArticleIds,
  }[type]

  return node_locale === default_locale ? id : table[node_locale][id] || id
}

export default useContentfulId
